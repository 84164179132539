import { useState, useEffect, useCallback, useRef } from 'react';
import firebase from 'firebase';
import axios from "axios";
import ImgCrop from 'antd-img-crop';
import { Drawer, Button, Collapse, Divider, Popconfirm, Form, Modal, Input, Card, Row, Col, Checkbox, Upload, Typography , message, Progress, Tooltip, notification, Select, DatePicker } from 'antd';
import { CloseOutlined, UploadOutlined } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { get, isEmpty, isNumber, isString, set } from 'lodash-es';

import { decodeString, encodeString, formatDate } from '../../../../util/misc';
import EdutechCloudServer from '../../../../EdutechCloudServer';

import PanelHead from '../../../../components/PanelHead';
import moment from 'moment';
import useBusinessModeMapper from '../../../../hooks/useBusinessModeMapper';
import CloudDatabase from '../../../../api/CloudDatabase';
import LogsPanel from '../../../../api/LogsPanel';

const database    = firebase.database();

const bloodGroups = ['A+', 'B+', 'AB+', 'O+', 'A-', 'B-', 'AB-', 'O-'];
 
 

const TeacherAssistantEditStudentDrawer = ({ size, onClose, visible, studentKey, zIndex, hideAddedBatchInfo, title, formSubmitText, active }) => {

    const panelStyle = { fontSize: '18px', margin: '0px 20px', marginTop: '30px' };
 
    const [form]                                                =   Form.useForm();
    const { Panel }                                             =   Collapse;
    const { t }                                                 =   useTranslation();
    const { db }                                                =   useSelector(({ auth }) => auth);

    const [zIndexValue, setZIndexValue]                         =  useState('1002');

    const [loading, setLoading]                                 =   useState(false);
    const [progressPercent, setProgressPercent]                 =   useState(0);
    const [showProgress, setShowProgress]                       =   useState(false);
    
    const [sName, setSName]                                     =   useState('');
    const [avatar, setAvatar]                                   =   useState('');
    const [phone, setPhone]                                     =   useState('');
    const [email, setEmail]                                     =   useState('');
    const [stdUid, setStdUid]                                   =   useState('');
    const [customId, setCustomId]                               =   useState('');
    const [parent1phone_, setParent1Phone_]                     =   useState('');
    const [parent2phone_, setParent2Phone_]                     =   useState('');
    const [rfidCode__, setRfidCode__]                             =   useState('');
    const [studentPic, setStudentPic]                           =   useState(undefined);
    const [uploadPicText, setUploadPicText]                     =   useState('Click to upload');
    const [sInstitution, setSInstitution]                       =   useState('unset');
    const [studentExtraInfo, setStudentExtraInfo]               =   useState({});

    const [stdAcceptBatches, setStdAcceptBatches]               =   useState([]);
    const [accBatchesJx, setAccBatchesJx]                       =   useState([]);
    const [modalJx, setModalJx]                                 =   useState([]);
    const [isModalVisible, setIsModalVisible]                   =   useState(false);
    const [unsavedChanges, setUnsavedChanges]                   =   useState(false);
    const [unsavedModal, setUnsavedModal]                       =   useState(false);
    const submitButton_REF                                      =   useRef();
    const [studentBloodGroup, setStudentBloodGroup]             =   useState('')
    const [studentDateOfBirth, setStudentDateOfBirth]           =   useState('');
    const [extraInfoField, setExtraInfoField]                   =   useState([]);
    const [isModifyCustomID, setIsModifyCustomID]               = useState(false); 
    
    const [searchTerm, setSearchTerm]                           = useState('');
    
    const businessModeMapped_Student                            = useBusinessModeMapper("Student");
    const businessModeMapped_student                            = useBusinessModeMapper("student"); 
    const businessModeMapped_Classroom                          = useBusinessModeMapper("Classroom");
    const businessModeMapped_Classrooms                         = useBusinessModeMapper("Classrooms");
    const businessModeMapped_classrooms                         = useBusinessModeMapper("classrooms");
    const businessModeMapped_Tuition                            = useBusinessModeMapper("Tuition");
    const businessModeMapped_batches                            = useBusinessModeMapper("batches");
    const businessModeMapped_Batch                              = useBusinessModeMapper("Batch");
    
    const [acceptedRoomWithoutPermission, setAcceptedRoomWithoutPermission] = useState([]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

  
    const deleteAcceptedBatch = useCallback(async ({tUID, grade, subject, batch, studentKey}) => {
      
        let promiseArrStdSide       = [];
        let promiseArrTeacherSide   = [];

        promiseArrStdSide.push(database.ref().child(`USERS/${studentKey}/AcceptedClasses/${batch}`).remove())

        await Promise.all(promiseArrStdSide);

        promiseArrTeacherSide.push(database.ref(`USERS/${tUID}/UserClass/${grade}/${subject}/Streams/${batch}/AcceptedStudents/${studentKey}`).remove());
        
        await Promise.all(promiseArrTeacherSide);

        setStdAcceptBatches(prev => {
            const prevClone = [...prev];
            const newAcceptedBatches = prevClone.filter(batch => batch.BatchKey !== batch);
            return newAcceptedBatches;
        })

        const userClass = get(db, ['VolatileLocalData','Moderating'],{});
        let localDataOfThisStudent = userClass[tUID]['VolatileLocalData']['UniqueStudentJSONList'][studentKey]
        LogsPanel.recordLogs({ 
            userUID: get(db, ['UID'], ''),
            eventName: 'Remove from Classroom',
            eventProperties: {
            logSummary: `${get(localDataOfThisStudent, ['Name'],'')} (${get(localDataOfThisStudent, ['customID'], '')}) - ${get(localDataOfThisStudent, ['Phone'],'')} - ${grade+"/"+subject+"/"+decodeString(get(db, ['VolatileLocalData','Moderating', tUID,'UserClass', grade, subject, 'Streams', batch, 'BatchName'], 'BatchName')) || "Unknown"}`,
            batchID: batch || "Unknown",
            studentUID: get(localDataOfThisStudent, ['UID'], ''),
            modifierUID: get(db, ['UID'], ''),
            modifierName: get(db, ['PublicInfo', 'UserName'], 'Unknown')
        }});
    
    }, [db]);


    // load student general information and student all accepted batches
    useEffect(() => {

        if (!db || visible === false) return ()=>{};

        console.log('TRIGGERED STUDENT EDIT PROFILE DRAWER USE-EFFECT 1..', studentKey);

        if(zIndex){
            setZIndexValue(zIndex)
        }


        if(active){

            if(active[0] !== ""){
                console.log('active', active)
                const [tuid, grade, subject, batch] = active;
                console.log('first', tuid, grade, subject, batch)
    
                const pathPrefix  = ["VolatileLocalData", "Moderating", tuid || ""];
    
                const isModifyCustomID__ = get(db, [...pathPrefix, ...['UserClass', grade, subject, 'Streams', batch, 'AcceptedModerators', db['UID'], 'permissionSpecificBatch', batch, 'modifyCustomID']], undefined) !== undefined ?
                            get(db, [...pathPrefix, ...['UserClass', grade, subject, 'Streams', batch, 'AcceptedModerators', db['UID'], 'permissionSpecificBatch', batch, 'modifyCustomID']], false)
                            :
                            get(db, [...pathPrefix, ...["Moderators", db["UID"], "Permission", "modifyCustomID"]], false);
                console.log('isModifyCustomID__', isModifyCustomID__)
                    
                setIsModifyCustomID(isModifyCustomID__);
            }
        }
        

        let studentUnvisibleClassroom = []

        let accBatches       = [];
        let accBatchJx       = [];
        if (studentKey){

            const userClass        = get(db, ['VolatileLocalData','Moderating'],{});

            let rfidCode___ = '';
            let parent1phone___ = '';
            let parent2phone___ = ''
            

            for(let tUID in userClass){
            
                let localDataOfThisStudent = userClass[tUID]['VolatileLocalData']['UniqueStudentJSONList'][studentKey]
                
                setStdUid(studentKey)
                
                if (localDataOfThisStudent === undefined){
                    continue;
                }
                const Name          = get(localDataOfThisStudent, ['Name'],'');
                const Email         = get(localDataOfThisStudent, ['Email'],'');
                const avatarURL     = get(localDataOfThisStudent, ['avatarURL'],'');
                const Phone         = get(localDataOfThisStudent, ['Phone'],'');
                const parent1phone  = get(localDataOfThisStudent, ['parent1phone'],'');
                const parent2phone  = get(localDataOfThisStudent, ['parent2phone'],'');
                const Institution   = get(localDataOfThisStudent, ['Institution'],'');
                const UID           = get(localDataOfThisStudent, ['UID'], '');
                const rfid_         = get(localDataOfThisStudent, ['RFIDCode'], '');
                let customID        = get(localDataOfThisStudent, ['customID'], '');

                if(rfidCode___ === ''){
                    rfidCode___ = rfid_
                }

                if(parent1phone___ === ''){
                    parent1phone___ = parent1phone
                }

                if(parent2phone___ === ''){
                    parent2phone___ = parent2phone
                }


                if (isNumber(customID)) customID = `ID-${customID}`;


                setSName(Name);
                setEmail(Email);
                setStdUid(UID);
                setPhone(Phone);
                setAvatar(avatarURL);
                setSInstitution(Institution)
                setCustomId(customID)
                setParent1Phone_(parent1phone___)
                setParent2Phone_(parent2phone___)
                setRfidCode__(rfidCode___)

                const studentCustomFields = get(db, ['StudentsCustomInfoFields'], {});
        
                let extraInfoJSON   = {}
                let fieldLists      = [];
                
                for(let fieldKey in studentCustomFields){
                    const fieldName = get(studentCustomFields, [fieldKey, 'name'], 'unknown')
                    if(fieldName !== 'unknown'){

                        fieldLists.push({
                            key         : fieldKey,
                            name        : fieldName,
                            label       : decodeString(fieldName),
                            placeholder : decodeString(fieldName),
                        })

                        if(localDataOfThisStudent[fieldName]){
                            if(fieldName === encodeString('Date Of Birth')){
                                const defaultDate = moment(localDataOfThisStudent[fieldName], 'DD-MM-YYYY');
                                set(extraInfoJSON, fieldName, defaultDate)
                                setStudentDateOfBirth(defaultDate); 
                            }
                            else if(fieldName === encodeString('Blood Group')){
                                set(extraInfoJSON, fieldName, decodeString(get(localDataOfThisStudent, [fieldName], '')))
                                setStudentBloodGroup(localDataOfThisStudent[fieldName])  
                            }
                            else {
                                set(extraInfoJSON, fieldName, decodeString(get(localDataOfThisStudent, [fieldName], '')))
                            }       
                        }
                    }            
                }

                setStudentExtraInfo(extraInfoJSON)
                    
                setExtraInfoField(fieldLists);


                form.setFieldsValue({
                    StudentName         : decodeString(Name),
                    StudentEmail        : decodeString(Email) === 'unset@gmail.com'? '' : decodeString(Email),
                    StudentPhone        : Phone !== '' ? decodeString(Phone).toString().substring(4) : Phone,
                    StudentID           : customID? customID.split('ID-').join("") : 'Unknown',
                    Institution         : Institution !== 'unset' || Institution !== '' ? Institution : 'Private',
                    Parent1Phone        : parent1phone___ !== '' ? decodeString(parent1phone___).toString().substring(4) : parent1phone___,
                    Parent2Phone        : parent2phone___ !== '' ? decodeString(parent2phone___).toString().substring(4) : parent2phone___,
                    StudentRFIDCode     : decodeString(rfidCode___),
                    ...extraInfoJSON
                })

                
                let acceptedClassesForThisStudent = get(localDataOfThisStudent, ['AcceptedClasses'], {});

                for (let grade in acceptedClassesForThisStudent){
                    for (let subject in acceptedClassesForThisStudent[grade]){

                        let uiSubject = get(db, ['VolatileLocalData','Moderating', tUID, 'UserClass',  grade ,  subject, 'UiSubject'], '');

                        for (let batch in acceptedClassesForThisStudent[grade][subject]){

                            const BatchName     = get(db, ['VolatileLocalData','Moderating', tUID, 'UserClass',  grade ,  subject, 'Streams', batch, 'BatchName'], 'unknown');

                           
                            if(BatchName === 'unknown') {

                                studentUnvisibleClassroom.push({
                                    Grade       : grade,
                                    Subject     : subject,
                                    BatchKey    : batch,
                                    TeacherUID  : tUID,
                                });

                                continue;
                            }
                            const StreamColor   = get(db, ['VolatileLocalData','Moderating', tUID, 'UserClass',  grade ,  subject, 'Streams', batch, 'StreamColor'], 'Not Found');
                            let TutionFee       = get(db, ['VolatileLocalData','Moderating', tUID, 'UserClass',  grade ,  subject, 'Streams', batch, 'Tution'], 0);
                            let teacherName     = get(db, ['VolatileLocalData','Moderating', tUID, 'PublicInfo','UserName'], 'Not Found');

                            let AppliedDate   = get(acceptedClassesForThisStudent, [grade ,  subject, batch, 'AppliedDate'], '');
                            let AcceptedDate    = get(acceptedClassesForThisStudent, [grade ,  subject, batch, 'AcceptedDate'], '');

                            //check to see if a modified tuition fee is present
                            let modTuitionFee   = get(db, ['VolatileLocalData','Moderating', tUID, 'UserClass',  grade , subject, 'Streams', batch, 'AcceptedStudents', studentKey, 'TuitionFee'], undefined);
                            if (modTuitionFee) TutionFee = modTuitionFee;

                            let batchData = {
                                teacherName : decodeString(teacherName),
                                Grade       : grade,
                                Subject     : subject,
                                uiSubject   : decodeString(uiSubject),
                                BatchKey    : batch,
                                TeacherUID  : tUID,
                                BatchName   : decodeString(BatchName),
                                StreamColor : StreamColor,
                                TuitionFee  : modTuitionFee ? modTuitionFee: TutionFee
                            }

                            if(AcceptedDate){
                                batchData['AcceptedDate'] = AcceptedDate
                            }
                            if(AppliedDate){
                                batchData['AppliedDate'] = AppliedDate
                            }

                            //set a boolean to show that this batch has a modiefied tuition fee for this student
                            if (modTuitionFee){
                                batchData['modTutionFee'] = true;
                            }

                            accBatches.push(batchData);

                            // populate all accepted batches jx
                            form.setFieldsValue({
                                [`Tution-${batch}`]: modTuitionFee ? modTuitionFee : TutionFee
                            })

                            accBatchJx.push(
                                <Col span={24} key={batch}>
                                <Card
                                    size="small"
                                    className="gx-acc-batch-widget gx-mb-3 gx-border-0"
            
                                >
                                    <div className="gx-d-flex gx-justify-content-between gx-align-items-center gx-pl-2 gx-pr-3">
                                        <div style={{width: '85%'}} className="gx-acc-batch-content-left gx-d-flex gx-justify-content-between gx-align-items-center">
                                            
                                    
                                            <div className="gx-fs-xl gx-font-weight-normal gx-text-dark-grey" style={{width: '70%'}}>
                                                <h4 className="gx-mb-0 gx-fs-xl gx-font-weight-normal gx-text-dark-grey">
                                                    <span className="gx-d-block gx-mb-1">{decodeString(teacherName)}</span>
                                                    <span className="gx-d-block gx-fs-md gx-mb-1  gx-text-medium-dark-grey">{grade}/{uiSubject !== '' ? decodeString(uiSubject) : decodeString(subject)}</span> {decodeString(BatchName)}
                                                </h4>
                                            </div>
                                            <span className='gx-mr-4' style={{ height: '25px', width: '2px', background: '#8C8C8C' }}></span>
            
                                            <div className="gx-fs-md gx-text-dark-grey gx-add-student-include-batch-card" style={{width: '45%'}}>
                                                <Form.Item 
                                                    className   =   "gx-mb-0 gx-mt-0"
                                                    label       =   {`Modified ${businessModeMapped_Tuition} Fee`}
                                                    name        =   {`Tution-${batch}`}
                                                    
                                                    
                                                >
                                                    <Input 
                                                        className       =   "ant-input-md gx-bg-transparent"
                                                        type            =   "text" 
                                                        placeholder     =   "Enter Amount" 
                                                        name            =   {`Tution-${batch}`}
                                                    
                                                    />
                                                </Form.Item>
                                        
                                            </div> 
                                        </div>
            
                                        <div className="gx-acc-batch-content-right">
                                            <div className="gx-d-flex gx-align-items-center gx-justify-content-end">
                                                
                                                    <Popconfirm
                                                        title       =   {`Are you sure? All data of this ${businessModeMapped_student} inside this ${businessModeMapped_batches} will be deleted!`}
                                                        onConfirm   =   {()=> deleteAcceptedBatch({tUID, grade, subject, batch, studentKey})}
                                                        onCancel    =   {() => console.log('Dont Delete..')}
                                                        okText      =   "Yes"
                                                        cancelText  =   "No"
                                                    >
                                                        <Tooltip placement="bottom" title={`Remove ${businessModeMapped_Student} from this ${businessModeMapped_Batch}`}>
                                                            <FontAwesomeIcon
            
                                                                className   =   "gx-ellipse gx-bg-danger gx-text-white gx-mr-2"
                                                                icon        =   {faXmark} 
                                                                
                                                            /> 
                                                            </Tooltip>
                                                    
                                                    </Popconfirm>
            
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{backgroundColor: StreamColor}} className="Border-card-color"></div>
                                </Card>
                            </Col>
                            )
                        }
                    }
                }
            }


            
        }

        
        setStdAcceptBatches(accBatches)
        setAccBatchesJx(accBatchJx)

        setAcceptedRoomWithoutPermission(studentUnvisibleClassroom)
   
    
    }, [visible, db, active, studentKey, form, deleteAcceptedBatch, zIndex, businessModeMapped_Batch, businessModeMapped_Student, businessModeMapped_Tuition, businessModeMapped_student, businessModeMapped_batches])

   
    const showModal = () => {
      setIsModalVisible(true);
    };

  
    const handleOk = (values) => {

        const { includeBatchCBOX } = values;

        let logsClassData           = [];

        let updates     =   {};

        for (let oneBatchInfo of includeBatchCBOX){
            let thisTeacherUID  = oneBatchInfo.split('|||||')[0];
            let thisGrade       = oneBatchInfo.split('|||||')[1];
            let thisSubject     = oneBatchInfo.split('|||||')[2];
            let thisBatch       = oneBatchInfo.split('|||||')[3];
            let tModifierData   = getTuitionModInputData(oneBatchInfo.split('|||||')[4], values);


            let BatchName       = decodeString(get(db, ['VolatileLocalData','Moderating', thisTeacherUID, 'UserClass', thisGrade, thisSubject, 'Streams', thisBatch, 'BatchName'], 'Unknown'));

            const stdObj = {
                BatchKey        : thisBatch,
                Grade           : thisGrade,
                Subject         : thisSubject,
                BatchName       : BatchName,
                TeacherUID      : thisTeacherUID,
                AcceptedDate    : formatDate(Date.now()),
                Type            : 'Physical',
            }
          

            if(tModifierData > -1) stdObj['TuitionFee']             = tModifierData;

            // add to student db
            database.ref(`USERS/${stdUid}/AcceptedClasses/${thisBatch}/`).update(stdObj);
    

            let studentData_TeacherSide = {
                Email           : email,
                Institution     : sInstitution,
                Name            : sName,
                Phone           : phone,
                Type            : 'Physical',
                UID             : stdUid,
                avatarURL       : avatar,
                AppliedDate     : formatDate(Date.now()),
                VideoPermission : 'Allowed',
                AcceptedDate    : formatDate(Date.now()),
                customID        : customId,
                
            };


            if(tModifierData > -1) studentData_TeacherSide['TuitionFee']         = tModifierData;

            if (parent1phone_) studentData_TeacherSide['parent1phone']               = parent1phone_;
            if (parent2phone_) studentData_TeacherSide['parent2phone']               = parent2phone_;
            if (rfidCode__) studentData_TeacherSide['RFIDCode']                     = rfidCode__;

            let extraStudentInfo = {};
         
                     
            for(let oneField of extraInfoField){
        
                if(!isEmpty(studentExtraInfo[oneField['name']]) || studentExtraInfo[oneField['name']] !== undefined){
                    set(extraStudentInfo, oneField['name'], encodeString(studentExtraInfo[oneField['name']]))
                }

                if(oneField['name'] === encodeString('Blood Group')){
                    set(extraStudentInfo, oneField['name'], studentBloodGroup)
                }

                if(oneField['name'] === encodeString('Date Of Birth')){
                    if(isString(studentDateOfBirth)){
                        set(extraStudentInfo, oneField['name'], studentDateOfBirth)
                    }
                    else{
                        set(extraStudentInfo, oneField['name'], moment(studentDateOfBirth).format("DD-MM-YYYY"))
                    }
                }
            
                
            }

            if(!isEmpty(extraStudentInfo)){
                studentData_TeacherSide = {...studentData_TeacherSide, ...extraStudentInfo}
            }

            // add to teacher db
            for (let oneKey in studentData_TeacherSide){
                updates[`USERS/${thisTeacherUID}/UserClass/${thisGrade}/${thisSubject}/Streams/${thisBatch}/AcceptedStudents/${stdUid}/${oneKey}`] = studentData_TeacherSide[oneKey];
            }

            //Collecting class data to save in logs
            logsClassData.push({batchID: thisBatch, grade: thisGrade, subject: thisSubject, batchName: BatchName})
        }


        CloudDatabase.update(updates)
            .then(()=>{
                message.success('Included successfully!');
                LogsPanel.recordLogs({ 
                    userUID: get(db, ['UID'], ''),
                    eventName: 'Add to Classroom',
                    eventProperties: {
                    logSummary: `${get(values, ['StudentName'],'')} (${get(values, ['StudentID'], '')}) - +880${get(values, ['StudentPhone'],'')} - ${logsClassData?.map(cd => `${cd?.grade}/${cd?.subject}/${cd?.batchName}`).join(", ")}`,
                    studentUID: studentKey || "",
                    batchID: logsClassData?.map(cd => cd?.batchID) || [], 
                    modifierUID: get(db, ['UID'], ''),
                    modifierName: get(db, ['PublicInfo', 'UserName'], 'Unknown')
                }});
                setIsModalVisible(false);
                setUnsavedChanges(true);
                form.resetFields(["includeBatchCBOX"])
            })
            .catch(e=>{
                message.error(e)
            })
    };

  
    const handleCancel = () => {
      setIsModalVisible(false);
    };
    
    
    useEffect(() => {

        if (!db || visible === false) return ()=>{};

        console.log('TRIGGERED STUDENT EDIT PROFILE DRAWER USE-EFFECT 2..', form);
        
        const stcAccBatchKey = new Set(stdAcceptBatches.map((item) => item.BatchKey));
        const addedBatchKeys = new Set(); // To track added batch keys and prevent duplicates
        let allBatchData = [];
        let userClass = get(db, ['VolatileLocalData', 'Moderating'], {});
    
        for (let tUID in userClass) {
            for (let grade in userClass[tUID]['UserClass']) {
                for (let subject in userClass[tUID]['UserClass'][grade]) {
                    let uiSubject = get(db, ['VolatileLocalData', 'Moderating', tUID, 'UserClass', grade, subject, 'UiSubject'], '');
    
                    for (let batch in userClass[tUID]['UserClass'][grade][subject]['Streams']) {
                        let moderatorsJSON = get(db, ['VolatileLocalData', 'Moderating', tUID, 'UserClass', grade, subject, 'Streams', batch, 'AcceptedModerators'], {});
                        for (let oneMod in moderatorsJSON) {
                            if (get(moderatorsJSON, [oneMod, 'Permission', 'manageStudents'], false) === true) {
                                let teacherName = get(db, ['VolatileLocalData', 'Moderating', tUID, 'PublicInfo', 'UserName'], 'Not Found');
                                const newBatchDataObj = { ...userClass[tUID]['UserClass'][grade][subject]['Streams'][batch] };
                                  
                                let TutionFee       = get(db, ['VolatileLocalData', 'Moderating', tUID, 'UserClass',  grade ,  subject, 'Streams', batch, 'Tution'], 0);
                                let modTuitionFee   = get(db, ['VolatileLocalData', 'Moderating', tUID, 'UserClass',  grade , subject, 'Streams', batch, 'AcceptedStudents', studentKey, 'TuitionFee'], undefined);

                                newBatchDataObj.teacherName = decodeString(teacherName);
                                newBatchDataObj.TeacherUID = tUID;
                                newBatchDataObj.BatchKey = batch;
                                newBatchDataObj.Grade = decodeString(grade);
                                newBatchDataObj.Subject = subject;
                                newBatchDataObj.uiSubject = decodeString(uiSubject);
                                newBatchDataObj.TuitionFee  = TutionFee

                                if (modTuitionFee) newBatchDataObj.TuitionFee = modTuitionFee;

                                if (modTuitionFee){
                                    newBatchDataObj['modTutionFee'] = true;
                                }
    
                                if (!addedBatchKeys.has(batch)) {
                                    allBatchData.push(newBatchDataObj);
                                    addedBatchKeys.add(batch);
                                }
                            }
                        }
                    }
                }
            }
        }
    
        const restBatchToInclude = allBatchData.filter(item => !stcAccBatchKey.has(item.BatchKey));
    
        // Apply the search filter
        const filteredBatches = restBatchToInclude.filter(batch => {
            const searchLowerCase = searchTerm.toLowerCase();
            return batch.teacherName.toLowerCase().includes(searchLowerCase) ||
                batch.Grade.toLowerCase().includes(searchLowerCase) ||
                decodeString(batch.Subject).toLowerCase().includes(searchLowerCase) ||
                decodeString(batch.BatchName).toLowerCase().includes(searchLowerCase) ||
                batch.uiSubject.toLowerCase().includes(searchLowerCase);
        });
    
        let batchesModalJx = [];
    
        filteredBatches.forEach((batch, index) => {
            form.setFieldsValue({
                [`Tution-${batch.BatchKey}`]: batch.TuitionFee ? batch.TuitionFee : batch.Tution
            })
    
            batchesModalJx.push(
                <Col span={24} key={index + batch.BatchKey}>
                    <Card
                        size="small"
                        className="gx-acc-batch-widget gx-mb-2 gx-border-0"
                    >
                        <div className="gx-d-flex gx-justify-content-between gx-align-items-center gx-pl-2 gx-pr-3">
                            <div style={{ width: '90%' }} className="gx-acc-batch-content-left gx-d-flex gx-justify-content-between gx-align-items-center">
                                <Checkbox className="gx-mr-3" value={`${batch.TeacherUID}|||||${batch.Grade}|||||${batch.Subject}|||||${batch.BatchKey}|||||${[`Tution-${batch.BatchKey}`]}`} />
    
                                <div className="gx-fs-xl gx-font-weight-normal gx-text-dark-grey" style={{ width: '70%' }}>
                                    <span className="gx-d-block gx-fs-lg gx-mb-1">{decodeString(batch.teacherName)}</span>
                                    <span className="gx-d-block gx-fs-md gx-mb-1 gx-text-medium-dark-grey">
                                        {batch.Grade}/{batch.uiSubject !== '' ? batch.uiSubject : decodeString(batch.Subject)}
                                    </span> 
                                    {decodeString(batch.BatchName)}
                                </div>
                                <span className='gx-mr-5' style={{ height: '25px', width: '2px', background: '#8C8C8C' }}></span>
    
                                <div className="gx-fs-md gx-text-dark-grey gx-add-student-include-batch-card" style={{ width: '45%' }}>
                                    <Form.Item 
                                        className   = "gx-mb-0 gx-mt-0"
                                        label       = {`Modified ${businessModeMapped_Tuition} Fee`}
                                        name        = {`Tution-${batch.BatchKey}`}
                                    >
                                        <Input 
                                            className="ant-input-md gx-bg-transparent"
                                            type="text" 
                                            placeholder="Enter Amount" 
                                            name={`Tution-${batch.BatchKey}`}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
    
                            <div className="gx-acc-batch-content-right">
                                <div className="gx-d-flex gx-align-items-center gx-justify-content-end">
                                    {/* Optional Additional Content */}
                                </div>
                            </div>
                        </div>
                        <div style={{ backgroundColor: batch.StreamColor }} className="Border-card-color"></div>
                    </Card>
                </Col>
            );
        });
    
        setModalJx(batchesModalJx);
  
    }, [visible, db, stdAcceptBatches, form, searchTerm, businessModeMapped_Tuition, studentKey]);
    

    const getTuitionModInputData = (name, values) =>{

        const newName = name.split('-'||'--').join('');

        for (let key in values) {
            const newKey = key.split('-'||'--').join('');

            if(newName === newKey){
                return values[key]
            }
        }
    }

    const normFile = (e) => {

        if (Array.isArray(e)) {
          return e;
        }
      
        return e?.fileList;
    };

    const checkStudentsExists = async (thisTeacherUID, thisGrade, thisSubject, thisBatch, thisStudentUID) => {
        const snapshot = await database.ref(`USERS/${thisTeacherUID}/UserClass/${thisGrade}/${thisSubject}/Streams/${thisBatch}/AcceptedStudents/${thisStudentUID}/Name`).once('value');
        return snapshot.exists();
    };
    


    const onFinish = (values) => {
        
        let toUpdateOBJ = {
            studentuid      : stdUid,                           //REQUIRED
            phone           : `+880${values.StudentPhone}`,     //Either phone or email must be provided
            name            : values.StudentName, 
            email           : values.StudentEmail, 
            Institution     : values.Institution,
            parent1phone    : values.Parent1Phone ? `+880${values.Parent1Phone}` : "+880",
            parent2phone    : values.Parent2Phone ? `+880${values.Parent2Phone}` : "+880",
            rfid            : values.StudentRFIDCode
        }

        editOneStudent({toUpdateOBJ,accStdBatches:stdAcceptBatches, acceptedRoomWithoutPermission, values, pictureObj: studentPic});

    };
    
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };



    const editOneStudent = async ({toUpdateOBJ, accStdBatches, acceptedRoomWithoutPermission, values, pictureObj}) => {
        
        // Collecting previous student info to compare with update info
        const previousLocalDataOfThisStudent = {
           Name: sName, Phone: phone, Email: email, customID: customId,  parent1phone: parent1phone_, parent2phone: parent2phone_,  StudentRFIDCode: rfidCode__, Institution: sInstitution
         };

        let { parent1phone, parent2phone, Institution, rfid } = toUpdateOBJ;
        let { StudentID } = values;

        let extraStudentInfo = {};

        for(let oneField of extraInfoField){
       
            if(!isEmpty(values[oneField['name']]) || values[oneField['name']] !== undefined){
                set(extraStudentInfo, oneField['name'], encodeString(values[oneField['name']]))
            }

            if(oneField['name'] === encodeString('Blood Group')){
                set(extraStudentInfo, oneField['name'], studentBloodGroup)
            }

            if(oneField['name'] === encodeString('Date Of Birth')){
                if(isString(studentDateOfBirth)){
                    set(extraStudentInfo, oneField['name'], studentDateOfBirth)
                }
                else{
                    set(extraStudentInfo, oneField['name'], moment(studentDateOfBirth).format("DD-MM-YYYY"))
                }
            }
            
        }

       
        setLoading(true);
        setShowProgress(true);
        setProgressPercent(1);
        const loadingKey    = 'loading-key';
        message.loading('Finding Student..', loadingKey);

        let allTeachersUIDsArr = [];
        const teacherJSON = get(db, ['VolatileLocalData', 'Moderating'], {});
        const restrictedTeacherJSON = get(db, ['VolatileRestrictedData', 'Moderating'], {});
        
        // Process teacherJSON
        for (const tuid in teacherJSON) {
            const adminedUID = get(teacherJSON, [tuid, 'Admined'], '');
            if(adminedUID){
                const teachersSnapshot = await database.ref(`USERS/${adminedUID}/Teachers`).once('value');
                if (teachersSnapshot.exists()) {
                    const teachersUIDArr = Object.keys(teachersSnapshot.val())
                    for(const tuid__ of teachersUIDArr){
                        if(!allTeachersUIDsArr.includes(tuid__)){
                            allTeachersUIDsArr.push(tuid__)
                        }
                    }
                }
            }
            else{
                if(!allTeachersUIDsArr.includes(tuid)){
                    allTeachersUIDsArr.push(tuid)
                }
            }
           
        }
        
        // Process restrictedTeacherJSON
        for (const tuid in restrictedTeacherJSON) {
            const adminedUID = get(restrictedTeacherJSON, [tuid, 'Admined'], '');
            if(adminedUID){
                const teachersSnapshot = await database.ref(`USERS/${adminedUID}/Teachers`).once('value');
                if (teachersSnapshot.exists()) {
                    const teachersUIDArr = Object.keys(teachersSnapshot.val())
                    for(const tuid__ of teachersUIDArr){
                        if(!allTeachersUIDsArr.includes(tuid__)){
                            allTeachersUIDsArr.push(tuid__)
                        }
                    }
                }
            }
            else{
                if(!allTeachersUIDsArr.includes(tuid)){
                    allTeachersUIDsArr.push(tuid)
                }
            }
        }
        
        console.log(allTeachersUIDsArr);

        EdutechCloudServer.postRequest('api-react-client-update-student-and-parent', toUpdateOBJ
        , async(response)=>{
        
            if (response.data){

                setProgressPercent(20);

                let studentUID  = response.data['UID'];
                let studentData = response.data;

                //need to check if the custom uid supplied clashes with any other account
                let {valid, clash,teacherName, batchName, err} = checkIfCustomID_IsValid({uid: studentUID, customID: `ID-${StudentID}`, rfid: rfid});
                if (valid === false){                   
                    let h;
                    if(err === 'phone'){
                        h = message.error(`Your given phone number is already in use by ${businessModeMapped_Student} ${decodeString(clash)} in ${businessModeMapped_Classroom} ${decodeString(batchName)} in Teacher account: ${teacherName}. Please use another!`);
                    }
                    else if(err === 'customID'){
                        h = message.error(`Your given custom ID is already in use by ${businessModeMapped_Student} ${decodeString(clash)} in ${businessModeMapped_Classroom} ${decodeString(batchName)} in Teacher account: ${teacherName}. Please use another!`);
                    }
                    else if(err === 'rfid'){
                        h = message.error(`Your given rfid is already in use by ${businessModeMapped_Student} ${decodeString(clash)} in ${businessModeMapped_Classroom} ${decodeString(batchName)} in Teacher account: ${teacherName}. Please use another!`);
                    }
                    else{
                        // h = '';
                    }

                    // let h = message.error(`Your given custom ID is already in use by ${businessModeMapped_Student} ${decodeString(clash)} in ${businessModeMapped_Classroom} ${decodeString(batchName)} in Teacher account: ${teacherName}. Please use another!`, 0);
                    setLoading(false);
                    setTimeout(()=>{
                        h();
                    }, 5000);
                    setProgressPercent(100);
                    setShowProgress(false);
                    return false;
                }


                //if a student photo was supplied then upload it first
                if (pictureObj){
                    setProgressPercent(30);
                    uploadStudentPhoto({
                        photo: pictureObj,
                        uid: studentUID,
                        loadKey: loadingKey,
                        callback: async(avatarURL)=>{

                            setProgressPercent(60);
                            studentData['PublicInfo']['avatarURL'] = avatarURL;
                            database.ref(`USERS/${studentUID}/PublicInfo`).update({'avatarURL': avatarURL});
                            message.loading('Student found, adding to Batches..', loadingKey);

                            let updates     =   {};


                            // Fetch accepted classes for a specific student from the database
                            const snapshot = await database.ref(`USERS/${studentUID}/AcceptedClasses`).once('value');

                            if (snapshot.exists()) {
                                const thisStudentAcceptedClasses = snapshot.val();

                                // Loop through each accepted class
                                for (const oneRoomKey in thisStudentAcceptedClasses) {
                                    const classInfo = thisStudentAcceptedClasses[oneRoomKey];
                                    
                                    // Fetch teacher UID, grade, subject, and batch key for the current class
                                    const tuid      = get(classInfo, ['TeacherUID'], classInfo['UID']);
                                    const grade     = classInfo['Grade'];
                                    const subject   = classInfo['Subject'];
                                    const batch     = classInfo['BatchKey'];
                                    
                                    console.log('Class details:', { tuid, grade, subject, batch, studentUID });

                                    if(!allTeachersUIDsArr.includes(tuid)){
                                        console.log(`Skip this teacher ${tuid}`)
                                        continue;
                                    }

                                    // Check if the student still exists in this batch (remote validation)
                                    const isStudentExists = await checkStudentsExists(tuid, grade, subject, batch, studentUID);
                                    console.log('Student exists:', isStudentExists);

                                    // If student no longer exists in this batch, skip to the next class
                                    if (!isStudentExists) {
                                        console.log('Student not found, skipping...');
                                        continue;
                                    }

                                    // Check if the current batch already exists in the local state array
                                    const isBatchKeyInLocalArray = accStdBatches.some(obj => obj.BatchKey === batch);
                                    console.log('Batch exists in local array:', isBatchKeyInLocalArray);

                                    // Skip adding this class if it’s already in the local state
                                    if (isBatchKeyInLocalArray) continue;

                                    // Build student data object for teacher's side with defaults and conditional fields
                                    let studentData_TeacherSide = {
                                        Email       : get(studentData, ['PublicInfo', 'UserEmail'], 'unset'),
                                        Institution : Institution || 'Private',
                                        Name        : get(studentData, ['PublicInfo', 'UserName'], 'unset'),
                                        Phone       : get(studentData, ['PublicInfo', 'UserPhone'], 'unset'),
                                        Type        : 'Physical',
                                        UID         : studentUID,
                                        avatarURL   : avatarURL,
                                        customID    : `ID-${StudentID}`,
                                        VideoPermission: 'Allowed',
                                    };

                                     // Encode RFID if it exists and meets length requirements
                                    if (rfid && rfid.length > 5) {
                                        studentData_TeacherSide['RFIDCode'] = encodeString(rfid);
                                    }
                                    else{ // Remove "RFIDCode"  entry if it does not contain valid data.
                                        updates[`USERS/${tuid}/UserClass/${grade}/${subject}/Streams/${batch}/AcceptedStudents/${studentUID}/RFIDCode`] = null;
                                    }

                                    // Include parent phone numbers if they are provided
                                    if (parent1phone) {
                                        studentData_TeacherSide['parent1phone'] = parent1phone;
                                    }
                                    else{ // Remove "parent1phone"  entry if it does not contain valid data.
                                        updates[`USERS/${tuid}/UserClass/${grade}/${subject}/Streams/${batch}/AcceptedStudents/${studentUID}/parent1phone`] = null;
                                    }
                                    if (parent2phone){ 
                                        studentData_TeacherSide['parent2phone'] = parent2phone;
                                    }
                                    else{ // Remove "parent2phone"  entry if it does not contain valid data.
                                        updates[`USERS/${tuid}/UserClass/${grade}/${subject}/Streams/${batch}/AcceptedStudents/${studentUID}/parent2phone`] = null;
                                    }

                                    // Merge any additional student information if present
                                    if (!isEmpty(extraStudentInfo)) {
                                        studentData_TeacherSide = { ...studentData_TeacherSide, ...extraStudentInfo };
                                    }

                                    // Add each property to the update path for batch update
                                    for (const key in studentData_TeacherSide) {
                                        updates[`USERS/${tuid}/UserClass/${grade}/${subject}/Streams/${batch}/AcceptedStudents/${studentUID}/${key}`] = studentData_TeacherSide[key];
                                    }
                                }
                            }

                            // visible classroom with permission
                            for (let oneBatchInfo of accStdBatches){
                                let thisTeacherUID  = oneBatchInfo['TeacherUID'];
                                let thisGrade       = oneBatchInfo['Grade'];
                                let thisSubject     = oneBatchInfo['Subject'];
                                let thisBatch       = oneBatchInfo['BatchKey'];
                                let tModifierData   = getTuitionModInputData(`Tution${oneBatchInfo['BatchKey']}`, values);
                                
                                let studentData_TeacherSide = {
                                    Email           : get(studentData, ['PublicInfo', 'UserEmail'], 'unset'),
                                    Institution     : Institution ? Institution : 'Private',
                                    Name            : get(studentData, ['PublicInfo', 'UserName'], 'unset'),
                                    Phone           : get(studentData, ['PublicInfo', 'UserPhone'], 'unset'),
                                    Type            : 'Physical',
                                    UID             : studentUID,
                                    avatarURL       : avatarURL,
                                    VideoPermission : 'Allowed',
                                    customID        : `ID-${StudentID}`
                                };

                                if(oneBatchInfo['AcceptedDate']){
                                    studentData_TeacherSide['AcceptedDate'] = oneBatchInfo['AcceptedDate']
                                }
        
                                if(oneBatchInfo['AppliedDate']){
                                    studentData_TeacherSide['AppliedDate'] = oneBatchInfo['AppliedDate']
                                }

                              
                                if(!hideAddedBatchInfo){
                                    if(tModifierData > -1) studentData_TeacherSide['TuitionFee'] = tModifierData;
                                }

                               

                                 // Encode RFID if it exists and meets length requirements
                                 if (rfid && rfid.length > 5) {
                                    studentData_TeacherSide['RFIDCode'] = encodeString(rfid);
                                }
                                else{ // Remove "RFIDCode"  entry if it does not contain valid data.
                                    updates[`USERS/${thisTeacherUID}/UserClass/${thisGrade}/${thisSubject}/Streams/${thisBatch}/AcceptedStudents/${studentUID}/RFIDCode`] = null;
                                }

                                // Include parent phone numbers if they are provided
                                if (parent1phone) {
                                    studentData_TeacherSide['parent1phone'] = parent1phone;
                                }
                                else{ // Remove "parent1phone"  entry if it does not contain valid data.
                                    updates[`USERS/${thisTeacherUID}/UserClass/${thisGrade}/${thisSubject}/Streams/${thisBatch}/AcceptedStudents/${studentUID}/parent1phone`] = null;
                                }
                                if (parent2phone){ 
                                    studentData_TeacherSide['parent2phone'] = parent2phone;
                                }
                                else{ // Remove "parent2phone"  entry if it does not contain valid data.
                                    updates[`USERS/${thisTeacherUID}/UserClass/${thisGrade}/${thisSubject}/Streams/${thisBatch}/AcceptedStudents/${studentUID}/parent2phone`] = null;
                                }


                                if(!isEmpty(extraStudentInfo)){
                                    studentData_TeacherSide = {...studentData_TeacherSide, ...extraStudentInfo}
                                }
                                
                                for (let oneKey in studentData_TeacherSide){
                                    updates[`USERS/${thisTeacherUID}/UserClass/${thisGrade}/${thisSubject}/Streams/${thisBatch}/AcceptedStudents/${studentUID}/${oneKey}`] = studentData_TeacherSide[oneKey];
                                }
                                
                                const studentData_StudentSide = {
                                    BatchKey        : thisBatch,
                                    Grade           : thisGrade,
                                    Subject         : thisSubject,
                                    TeacherUID      : thisTeacherUID,
                                    Type            : 'Physical',
                                }

                                if(oneBatchInfo['AcceptedDate']){
                                    studentData_StudentSide['AcceptedDate'] = oneBatchInfo['AcceptedDate']
                                }
        
                                if(oneBatchInfo['AppliedDate']){
                                    studentData_StudentSide['AppliedDate'] = oneBatchInfo['AppliedDate']
                                }
        
        
                                if(!hideAddedBatchInfo){
                                    if(tModifierData > -1) studentData_StudentSide['TuitionFee'] = tModifierData;
                                }

        
                                // add to student db
                                for (let oneKey in studentData_StudentSide){
                                    updates[`USERS/${studentUID}/AcceptedClasses/${thisBatch}/${oneKey}`] = studentData_StudentSide[oneKey];
                                }

                            }
                           

        
                            setProgressPercent(80);
                            CloudDatabase.update(updates)
                                .then(()=>{
                                   

                                    let updateInfo = "";
                                    let previousInfo = "Previous ";
                                    let removedInfo = "";
                                    
                                    let hasUpdates = false;
                                    
                                    if (previousLocalDataOfThisStudent.Name !== values.StudentName) {
                                      updateInfo += `Name: ${values.StudentName}, `;
                                      previousInfo += `Name: ${previousLocalDataOfThisStudent.Name}, `;
                                      hasUpdates = true;

                                      console.log('values.StudentName', values.StudentName)
                                    }
                                    
                                    if (previousLocalDataOfThisStudent.Phone !== `+880${values.StudentPhone}`) {
                                      updateInfo += `Phone: +880${values.StudentPhone}, `;
                                      previousInfo += `Phone: ${previousLocalDataOfThisStudent.Phone}, `;
                                      hasUpdates = true;
                                    }
                                    
                                    if (previousLocalDataOfThisStudent.Email !== (values.StudentEmail || "unset@gmail.com")) {
                                        if(values.StudentEmail.length !== 0){
                                        updateInfo += `Email: ${values.StudentEmail}, `;
                                        previousInfo += `Email: ${previousLocalDataOfThisStudent.Email || "unset@gmail.com"}, `;
                                        hasUpdates = true;
                                        }else{
                                            removedInfo += `Email, `;
                                            previousInfo += `Email: ${previousLocalDataOfThisStudent.Email || "unset@gmail.com"}, `;
                                            hasUpdates = true;
                                        }
                                    }
                                    
                                    if (previousLocalDataOfThisStudent.customID !== `ID-${values.StudentID}`) {
                                        updateInfo += `StudentID: ${values.StudentID}, `;
                                        previousInfo += `StudentID: ${previousLocalDataOfThisStudent.customID || "Unknown"}, `;
                                        hasUpdates = true;
                                    }
                    
                                    if (previousLocalDataOfThisStudent.parent1phone !== (`+880${values.Parent1Phone}` || "Unknown")) {
                                        if(values.Parent1Phone !== ""){
                                            updateInfo += `Parent 1 Phone: +880${values.Parent1Phone}, `;
                                            previousInfo += `Parent 1 Phone: ${previousLocalDataOfThisStudent.parent1phone || "Unknown"}, `;
                                            hasUpdates = true;
                                        }else{
                                            removedInfo += `Parent 1 Phone, `;
                                            previousInfo += `Parent 1 Phone: ${previousLocalDataOfThisStudent.parent1phone || "Unknown"}, `;
                                            hasUpdates = true;
                                        }
                                    }
                    
                                    if (previousLocalDataOfThisStudent.parent2phone !== (`+880${values.Parent2Phone}` || "Unknown")) {
                                    if(values.Parent2Phone.length !== 0) {
                                        updateInfo += `Parent 2 Phone: +880${values.Parent2Phone}, `;
                                        previousInfo += `Parent 2 Phone: ${previousLocalDataOfThisStudent.parent2phone || "Unknown"}, `;
                                        hasUpdates = true;
                                    }else{
                                        removedInfo += `Parent 2 Phone, `;
                                        previousInfo += `Parent 2 Phone: ${previousLocalDataOfThisStudent.parent2phone || "Unknown"}, `;
                                        hasUpdates = true;
                                    }
                                    }
                    
                                    if (previousLocalDataOfThisStudent.Institution !== values.Institution) {
                                      updateInfo += `Institution: ${values.Institution}, `;
                                      previousInfo += `Institution: ${previousLocalDataOfThisStudent.Institution || "Unknown"}, `;
                                      hasUpdates = true;
                                    }
                                    
                                    if (values.StudentRFIDCode) {
                                      updateInfo += `RFID Code: ${values.StudentRFIDCode || "Unknown"}, `;
                                      previousInfo += `RFID Code: ${previousLocalDataOfThisStudent.RFIDCode || "Unknown"}, `;
                                      hasUpdates = true;
                                    }
                                    
                                    if (hasUpdates) {
                                      updateInfo = updateInfo.replace(/, $/, "");
                                      previousInfo = previousInfo.replace(/, $/, "");
                                      removedInfo = removedInfo.replace(/, $/, "");
                    
                                      LogsPanel.recordLogs({ 
                                        userUID: get(db, ['UID'], ''),
                                        eventName: 'Update Student Info',
                                        eventProperties: {
                                        logSummary: `${updateInfo.length !== 0 ? `Update Student ${updateInfo} - ` : ""}` + previousInfo + `${removedInfo.length !== 0 ? ` - Removed ${removedInfo}` : ""}`,
                                        studentUID: studentKey || "",
                                        modifierUID: get(db, ['UID'], ''),
                                        modifierName: get(db, ['PublicInfo', 'UserName'], 'Unknown')
                                       }});
                                    }
                                    form.resetFields();
                                    onClose();
                                    setUnsavedChanges(false);
                                    setUnsavedModal(false);
                                    setProgressPercent(100);
                                    setShowProgress(false);
                                    setStudentPic(undefined)
                                    message.success('Student has been updated successfully!', loadingKey);
                                    setLoading(false);
                                })
                                .catch(e=>{
                                    setLoading(false);
                                    onClose();
                                    setUnsavedChanges(false);
                                    setUnsavedModal(false);
                                    setShowProgress(false);
                                    message.error(e, loadingKey);
                                })
                        }
                    })
                }
                else{

                    message.loading('Student found, adding to Batches..', loadingKey);
    
                    let updates     =   {};

                    // Fetch accepted classes for a specific student from the database
                    const snapshot = await database.ref(`USERS/${studentUID}/AcceptedClasses`).once('value');

                    if (snapshot.exists()) {
                        const thisStudentAcceptedClasses = snapshot.val();

                        // Loop through each accepted class
                        for (const oneRoomKey in thisStudentAcceptedClasses) {
                            const classInfo = thisStudentAcceptedClasses[oneRoomKey];
                            
                            // Fetch teacher UID, grade, subject, and batch key for the current class
                            const tuid      = get(classInfo, ['TeacherUID'], classInfo['UID']);
                            const grade     = classInfo['Grade'];
                            const subject   = classInfo['Subject'];
                            const batch     = classInfo['BatchKey'];
                            
                            console.log('Class details:', { tuid, grade, subject, batch, studentUID });

                            if(!allTeachersUIDsArr.includes(tuid)){
                                console.log(`Skip this teacher ${tuid}`)
                                continue;
                            }

                            // Check if the student still exists in this batch (remote validation)
                            const isStudentExists = await checkStudentsExists(tuid, grade, subject, batch, studentUID);
                            console.log('Student exists:', isStudentExists);

                            // If student no longer exists in this batch, skip to the next class
                            if (!isStudentExists) {
                                console.log('Student not found, skipping...');
                                continue;
                            }

                            // Check if the current batch already exists in the local state array
                            const isBatchKeyInLocalArray = accStdBatches.some(obj => obj.BatchKey === batch);
                            console.log('Batch exists in local array:', isBatchKeyInLocalArray);

                            // Skip adding this class if it’s already in the local state
                            if (isBatchKeyInLocalArray) continue;

                            // Build student data object for teacher's side with defaults and conditional fields
                            let studentData_TeacherSide = {
                                Email       : get(studentData, ['PublicInfo', 'UserEmail'], 'unset'),
                                Institution : Institution || 'Private',
                                Name        : get(studentData, ['PublicInfo', 'UserName'], 'unset'),
                                Phone       : get(studentData, ['PublicInfo', 'UserPhone'], 'unset'),
                                Type        : 'Physical',
                                UID         : studentUID,
                                avatarURL   : get(studentData, ['PublicInfo', 'avatarURL'], 'unset'),
                                VideoPermission: 'Allowed',
                                customID    : `ID-${StudentID}`
                            };

                            // Encode RFID if it exists and meets length requirements
                            if (rfid && rfid.length > 5) {
                                studentData_TeacherSide['RFIDCode'] = encodeString(rfid);
                            }
                            else{ // Remove "RFIDCode"  entry if it does not contain valid data.
                                updates[`USERS/${tuid}/UserClass/${grade}/${subject}/Streams/${batch}/AcceptedStudents/${studentUID}/RFIDCode`] = null;
                            }

                            // Include parent phone numbers if they are provided
                            if (parent1phone) {
                                studentData_TeacherSide['parent1phone'] = parent1phone;
                            }
                            else{ // Remove "parent1phone"  entry if it does not contain valid data.
                                updates[`USERS/${tuid}/UserClass/${grade}/${subject}/Streams/${batch}/AcceptedStudents/${studentUID}/parent1phone`] = null;
                            }
                            if (parent2phone){ 
                                studentData_TeacherSide['parent2phone'] = parent2phone;
                            }
                            else{ // Remove "parent2phone"  entry if it does not contain valid data.
                                updates[`USERS/${tuid}/UserClass/${grade}/${subject}/Streams/${batch}/AcceptedStudents/${studentUID}/parent2phone`] = null;
                            }

                            // Merge any additional student information if present
                            if (!isEmpty(extraStudentInfo)) {
                                studentData_TeacherSide = { ...studentData_TeacherSide, ...extraStudentInfo };
                            }

                            // Add each property to the update path for batch update
                            for (const key in studentData_TeacherSide) {
                                updates[`USERS/${tuid}/UserClass/${grade}/${subject}/Streams/${batch}/AcceptedStudents/${studentUID}/${key}`] = studentData_TeacherSide[key];
                            }
                        }
                    }


                    // visible classroom with permission
                    for (let oneBatchInfo of accStdBatches){
                     
                        let thisTeacherUID  = oneBatchInfo['TeacherUID'];
                        let thisGrade       = oneBatchInfo['Grade'];
                        let thisSubject     = oneBatchInfo['Subject'];
                        let thisBatch       = oneBatchInfo['BatchKey'];
                        let tModifierData   = getTuitionModInputData(`Tution${oneBatchInfo['BatchKey']}`, values)
    
                        let studentData_TeacherSide = {
                            Email           : get(studentData, ['PublicInfo', 'UserEmail'], 'unset'),
                            Institution     : Institution ? Institution : 'Private',
                            Name            : get(studentData, ['PublicInfo', 'UserName'], 'unset'),
                            Phone           : get(studentData, ['PublicInfo', 'UserPhone'], 'unset'),
                            Type            : 'Physical',
                            UID             : studentUID,
                            avatarURL       : get(studentData, ['PublicInfo', 'avatarURL'], 'unset'),
                            VideoPermission : 'Allowed',
                            customID        : `ID-${StudentID}`
                        };


                        if(oneBatchInfo['AcceptedDate']){
                            studentData_TeacherSide['AcceptedDate'] = oneBatchInfo['AcceptedDate']
                        }

                        if(oneBatchInfo['AppliedDate']){
                            studentData_TeacherSide['AppliedDate'] = oneBatchInfo['AppliedDate']
                        }

                        if(!hideAddedBatchInfo){
                            if(tModifierData > -1) studentData_TeacherSide['TuitionFee'] = tModifierData;
                        }
                        
                        // Encode RFID if it exists and meets length requirements
                        if (rfid && rfid.length > 5) {
                            studentData_TeacherSide['RFIDCode'] = encodeString(rfid);
                        }
                        else{ // Remove "RFIDCode"  entry if it does not contain valid data.
                            updates[`USERS/${thisTeacherUID}/UserClass/${thisGrade}/${thisSubject}/Streams/${thisBatch}/AcceptedStudents/${studentUID}/RFIDCode`] = null;
                        }

                        // Include parent phone numbers if they are provided
                        if (parent1phone) {
                            studentData_TeacherSide['parent1phone'] = parent1phone;
                        }
                        else{ // Remove "parent1phone"  entry if it does not contain valid data.
                            updates[`USERS/${thisTeacherUID}/UserClass/${thisGrade}/${thisSubject}/Streams/${thisBatch}/AcceptedStudents/${studentUID}/parent1phone`] = null;
                        }
                        if (parent2phone){ 
                            studentData_TeacherSide['parent2phone'] = parent2phone;
                        }
                        else{ // Remove "parent2phone"  entry if it does not contain valid data.
                            updates[`USERS/${thisTeacherUID}/UserClass/${thisGrade}/${thisSubject}/Streams/${thisBatch}/AcceptedStudents/${studentUID}/parent2phone`] = null;
                        }

                        if(!isEmpty(extraStudentInfo)){
                            studentData_TeacherSide = {...studentData_TeacherSide, ...extraStudentInfo}
                        }

                        for (let oneKey in studentData_TeacherSide){
                            updates[`USERS/${thisTeacherUID}/UserClass/${thisGrade}/${thisSubject}/Streams/${thisBatch}/AcceptedStudents/${studentUID}/${oneKey}`] = studentData_TeacherSide[oneKey];
                        }
                        
                        const studentData_StudentSide = {
                            BatchKey        : thisBatch,
                            Grade           : thisGrade,
                            Subject         : thisSubject,
                            TeacherUID      : thisTeacherUID,
                            Type            : 'Physical',
                        }

                        if(oneBatchInfo['AcceptedDate']){
                            studentData_StudentSide['AcceptedDate'] = oneBatchInfo['AcceptedDate']
                        }

                        if(oneBatchInfo['AppliedDate']){
                            studentData_StudentSide['AppliedDate'] = oneBatchInfo['AppliedDate']
                        }


                        if(!hideAddedBatchInfo){
                            if(tModifierData > -1) studentData_StudentSide['TuitionFee'] = tModifierData;
                        }

                        // add to student db
                        for (let oneKey in studentData_StudentSide){
                            updates[`USERS/${studentUID}/AcceptedClasses/${thisBatch}/${oneKey}`] = studentData_StudentSide[oneKey];
                        }

                    }


                    setProgressPercent(65);
                    CloudDatabase.update(updates)
                        .then(()=>{
                            
                            

                            let updateInfo = "";
                                    let previousInfo = "Previous ";
                                    let removedInfo = "";
                                    
                                    let hasUpdates = false;
                                    
                                    if (previousLocalDataOfThisStudent.Name !== values.StudentName) {
                                      updateInfo += `Name: ${values.StudentName}, `;
                                      previousInfo += `Name: ${previousLocalDataOfThisStudent.Name}, `;
                                      hasUpdates = true;

                                      console.log('values.StudentName', values.StudentName)
                                    }
                                    
                                    if (previousLocalDataOfThisStudent.Phone !== `+880${values.StudentPhone}`) {
                                      updateInfo += `Phone: +880${values.StudentPhone}, `;
                                      previousInfo += `Phone: ${previousLocalDataOfThisStudent.Phone}, `;
                                      hasUpdates = true;
                                    }
                                    
                                    if (previousLocalDataOfThisStudent.Email !== (values.StudentEmail || "unset@gmail.com")) {
                                        if(values.StudentEmail.length !== 0){
                                        updateInfo += `Email: ${values.StudentEmail}, `;
                                        previousInfo += `Email: ${previousLocalDataOfThisStudent.Email || "unset@gmail.com"}, `;
                                        hasUpdates = true;
                                        }else{
                                            removedInfo += `Email, `;
                                            previousInfo += `Email: ${previousLocalDataOfThisStudent.Email || "unset@gmail.com"}, `;
                                            hasUpdates = true;
                                        }
                                    }
                                    
                                    if (previousLocalDataOfThisStudent.customID !== `ID-${values.StudentID}`) {
                                        updateInfo += `Student ID: ${values.StudentID}, `;
                                        previousInfo += `Student ID: ${previousLocalDataOfThisStudent.customID || "Unknown"}, `;
                                        hasUpdates = true;
                                    }
                    
                                    if (previousLocalDataOfThisStudent.parent1phone !== (`+880${values.Parent1Phone}` || "Unknown")) {
                                        if(values.Parent1Phone !== ""){
                                            updateInfo += `Parent 1 Phone: +880${values.Parent1Phone}, `;
                                            previousInfo += `Parent 1 Phone: ${previousLocalDataOfThisStudent.parent1phone || "Unknown"}, `;
                                            hasUpdates = true;
                                        }else{
                                            removedInfo += `Parent 1 Phone, `;
                                            previousInfo += `Parent 1 Phone: ${previousLocalDataOfThisStudent.parent1phone || "Unknown"}, `;
                                            hasUpdates = true;
                                        }
                                    }
                    
                                    if (previousLocalDataOfThisStudent.parent2phone !== (`+880${values.Parent2Phone}` || "Unknown")) {
                                    if(values.Parent2Phone.length !== 0) {
                                        updateInfo += `Parent 2 Phone: +880${values.Parent2Phone}, `;
                                        previousInfo += `Parent 2 Phone: ${previousLocalDataOfThisStudent.parent2phone || "Unknown"}, `;
                                        hasUpdates = true;
                                    }else{
                                        removedInfo += `Parent 2 Phone, `;
                                        previousInfo += `Parent 2 Phone: ${previousLocalDataOfThisStudent.parent2phone || "Unknown"}, `;
                                        hasUpdates = true;
                                    }
                                    }
                    
                                    if (previousLocalDataOfThisStudent.Institution !== values.Institution) {
                                      updateInfo += `Institution: ${values.Institution}, `;
                                      previousInfo += `Institution: ${previousLocalDataOfThisStudent.Institution || "Unknown"}, `;
                                      hasUpdates = true;
                                    }
                                    
                                    if (values.StudentRFIDCode) {
                                      updateInfo += `RFID Code: ${values.StudentRFIDCode || "Unknown"}, `;
                                      previousInfo += `RFID Code: ${previousLocalDataOfThisStudent.RFIDCode || "Unknown"}, `;
                                      hasUpdates = true;
                                    }
                                    
                                    if (hasUpdates) {
                                      updateInfo = updateInfo.replace(/, $/, "");
                                      previousInfo = previousInfo.replace(/, $/, "");
                                      removedInfo = removedInfo.replace(/, $/, "");
                    
                                      LogsPanel.recordLogs({ 
                                        userUID: get(db, ['UID'], ''),
                                        eventName: 'Update Student Info',
                                        eventProperties: {
                                        logSummary: `${updateInfo.length !== 0 ? `Update Student: ${updateInfo} - ` : ""}` + previousInfo + `${removedInfo.length !== 0 ? ` - Removed ${removedInfo}` : ""}`,
                                        studentUID: studentKey || "",
                                        modifierUID: get(db, ['UID'], ''),
                                        modifierName: get(db, ['PublicInfo', 'UserName'], 'Unknown')
                                       }});
                                    }

                            setUnsavedModal(false);
                            form.resetFields();
                            
                            onClose();
                            setUnsavedChanges(false);
                            setProgressPercent(100);
                            setShowProgress(false);
                            message.success('Student has been updated successfully!', loadingKey);
                            setLoading(false);
                        })
                        .catch(e=>{
                            setLoading(false);
                            onClose();
                            setUnsavedChanges(false);
                            setUnsavedModal(false);
                            message.error(e, loadingKey);
                            setShowProgress(false);
                        })
                }


            }
            else{
                message.error(`Error ${response.error}`);
                setLoading(false);
                setShowProgress(false);
            }
        });
    }

    //will return obj {valid, clash}
    const checkIfCustomID_IsValid = ({uid, customID, rfid}) => {
    
        let valid       = true;
        let clash       = 'No Clash';
        let batchName   = '';
        let mapper      = {};
        let rfidMapper  = {};
        //first create a mapper fn with custom-uid to UID
        let userClass = get(db, ['VolatileLocalData', 'Moderating'], {});
    
        for(let tUID in userClass){
            for (let grade in userClass[tUID]['UserClass']){
                for (let subject in userClass[tUID]['UserClass'][grade]){
                    for (let batch in userClass[tUID]['UserClass'][grade][subject]['Streams']){

                        let acceptedStudents = get(userClass[tUID]['UserClass'][grade][subject]['Streams'], [batch, 'AcceptedStudents'], {});
                    
                        let teacherName             = get(db, ['VolatileLocalData','Moderating', tUID, 'PublicInfo','UserName'], 'Not Found');
    
                        for (let oneSUID in acceptedStudents){
                            if (acceptedStudents[oneSUID]['customID']){
                                let this_customID = acceptedStudents[oneSUID]['customID'];
                                let thisStudentRFID = get(acceptedStudents, [oneSUID, 'RFIDCode'], '');
                                
                                mapper[this_customID] = {
                                    uid: oneSUID,
                                    teacherName: decodeString(teacherName),
                                    name: acceptedStudents[oneSUID]['Name'],
                                    batch: userClass[tUID]['UserClass'][grade][subject]['Streams'][batch]['BatchName']
                                }

                                rfidMapper[thisStudentRFID] = {
                                    rfid: thisStudentRFID,
                                    uid: oneSUID,
                                    teacherName: decodeString(teacherName),
                                    name: acceptedStudents[oneSUID]['Name'],
                                    batch: userClass[tUID]['UserClass'][grade][subject]['Streams'][batch]['BatchName']
                                }
                            }
                        }
                    }
                }
            }
        }


        //if this students provided custom ID is already in user by another UID
        if (mapper[customID]){
            if (mapper[customID]['uid'] !== uid){
                return {valid:false, teacherName: mapper[customID]['teacherName'], clash: mapper[customID]['name'], batchName: mapper[customID]['batch']}
            }
        }

        //if this students provided rfid is already in user by another UID
        if(rfid){
            if (rfidMapper[rfid]){
                if (rfidMapper[rfid]['rfid'] === rfid){
                    if(rfidMapper[rfid]['uid'] === uid){
                        return {valid, clash, batchName} // thats means rfid field does not changed for this student
                    }
                    return {valid:false, err:'rfid', teacherName: rfidMapper[rfid]['teacherName'], clash: rfidMapper[rfid]['name'], batchName: rfidMapper[rfid]['batch']}
                }
            }
            else{
                return {valid, clash, batchName}
            }
        }

        return {valid, clash, batchName}
    }


    const uploadStudentPhoto = async ({photo, uid,loadKey, callback}) => {

        message.loading('Uploading Student Picture..', loadKey);
        EdutechCloudServer.postRequest('upload-avatar', {}, async (res) => {
            if (res.msg === 'success'){

                let uploadUrl                           = res.uploadUrl;
                let uploadAuthorizationToken            = res.uploadAuthorizationToken;
                let filename                            = `${uid}-avatar.png`;
            
                await axios.post( uploadUrl, photo, {
                withCredentials: true,
                headers: {
                    Authorization: uploadAuthorizationToken,
                    'Content-Type': 'b2/x-auto',
                    'X-Bz-File-Name': `${filename}`,
                    'X-Bz-Content-Sha1': 'do_not_verify',
                },
                onUploadProgress: ({ loaded, total }) => {
                    const totalProgress = parseInt((loaded / total) * 100);
                    message.loading(`Uploading Student Picture ${totalProgress}%`, loadKey);
                }
                } )
            
                let friendlyURL = `https://f002.backblazeb2.com/file/Edutech-Avatars/${filename}`


                database.ref(`USERS/${uid}/PublicInfo`).update({
                    'avatarURL': friendlyURL
                })
                .then(()=>{
                    message.loading(`Student Picture uploaded successfully..`, loadKey);
                    setUnsavedChanges(true);
                    callback(friendlyURL);
                })
            }

            else{
                message.error('Image Upload Failed. Server could not provide valid upload token');
            }
        });

    }


    const onCloseDrawer = () => {
        if (unsavedChanges === false){
            onClose();
        }
        else{
            //throw popup if user is sure 
            setUnsavedModal(true);
        }
    }


    const validateCustomIDInput = (rule, value, callback) => {
        // Updated regex pattern to approve a single alphanumeric prefix, optional hyphen, followed by digits
        const regexPattern = /^[A-Za-z0-9]+-?\d+$/;
    
        const myRegex = new RegExp(regexPattern); 
    
        if (myRegex.test(value)) {
            callback();
        } else {
            callback('Invalid input. Please follow the specified pattern: alphanumeric characters optionally followed by a hyphen and numbers (e.g., EDU123, EDU-12345, SHA2-728592).');
        }
    };
    

    return (
        <>
            <Drawer
                size        =   {size}
                placement   =   "right"
                closable    =   {false}
                onClose     =   {onCloseDrawer}
                visible     =   {visible}
                zIndex      =   {zIndexValue}
            >
                <Form
                    form                    =   {form}
                    name                    =   "Add One Student Modal Drawer"
                    onFinish                =   {onFinish}
                    onFinishFailed          =   {onFinishFailed}
                    layout                  =   'vertical'
                    className               =   "gx-force-two-cols"
                    onValuesChange          =   {(e)=>setUnsavedChanges(true)}
                >
                    <div className="drawer-content">
                        <div
                            className   =   "drawer-head"
                            style       =   {{ display: 'flex', justifyContent: 'space-between', padding: '30px 20px 5px 30px' }}
                        >
                            <div
                                className   =   "drawer-head-left"
                                style       =   {{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                            >

                                <CloseOutlined
                                    onClick     =   {onCloseDrawer}
                                    className   =   "gx-mr-3"
                                    style       =   {{ fontSize: '22px', cursor: 'pointer' }}
                                />

                                    <div className="drawer-title">
                                    <Typography.Title className='gx-mb-0' level={4}>{title ? title : t(`Edit ${businessModeMapped_Student} Profile`)}</Typography.Title>
                                        <span>{t("You may use their Phone and/or Email")}</span>
                                    </div>
                            </div>
                            <div className="drawer-head-right" style={{ display: 'flex', alignItems: 'center' }}>


                                <Button
                                    size        =   "medium"
                                    htmlType    =   "submit"
                                    className   =   "gx-btn-dark gx-mb-0"
                                    ref         =   {submitButton_REF}
                                    loading     = {loading}
                                >
                                    {formSubmitText ? formSubmitText : t("Save Changes")}
                                </Button>


                            </div>
                        </div>

                        <Divider />

                        <div className="drawer-body" style={{ padding: '0px 0px 20px 0' }}>

                            {showProgress ?

                                <div className='gx-mr-5 gx-ml-5'>
                                    <Progress
                                        strokeColor={{
                                            '0%': '#108ee9',
                                            '100%': '#87d068',
                                        }}
                                        percent={progressPercent}
                                    />
                                </div> :
                                <></>
                            }


                            <Collapse bordered={false} defaultActiveKey={['1', '2', '4']} ghost={true}>
                                <Panel 
                                    header={<PanelHead title= {t("General")} isLineAfterText={false} titleLevel={5} />} 
                                    key="1" style={{ fontSize: '18px', margin: '0px 20px', marginTop: '5px' }} 
                                >
                                    <Row justify='space-evenly'>
                                        <Col span={12} >

                                            <Form.Item
                                                className   =   "gx-mb-0 gx-mt-0"
                                                label       =   {t(`${businessModeMapped_Student} Name`)}
                                                rules       =   {[{ required: true, message: t(`Please enter your ${businessModeMapped_student} name!`) }]}
                                                name        =   "StudentName"
                                                required
                                                tooltip     =   {t("Must be provided")}

                                            >
                                                <Input
                                                    type        =   "text"
                                                    placeholder =   {t(`${businessModeMapped_Student} Name`)}
                                                    name        =   "StudentName"
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                className   =   "gx-mb-0 gx-mt-3"
                                                label       =   {t(`${businessModeMapped_Student} Phone`)}
                                                rules       =   {[{ required: true, message: t(`Please enter your ${businessModeMapped_student} phone!`) }]}
                                                name        =   "StudentPhone"
                                                required
                                                tooltip     =   {t("Must be provided")}

                                            >
                                                <Input
                                                    type        =   "text"
                                                    placeholder =   "1700766173"
                                                    name        =   "StudentPhone"
                                                    prefix      =   "+880"
                                                />
                                            </Form.Item>


                                            <Form.Item
                                                className   =   "gx-mb-0 gx-mt-3"
                                                label       =   {t(`${businessModeMapped_Student} Email`)}
                                                rules       =   {[{ required: false, message: t(`Please enter your ${businessModeMapped_student} email!`) }]}
                                                name        =   "StudentEmail"
                                            >
                                                <Input
                                                    type        =   "email"
                                                    placeholder =   "john@gmail.com"
                                                    name        =   "StudentEmail"
                                                />
                                            </Form.Item>

                                            {get(db, ['ReactAppMode'], undefined) === 'SMS_LMS_GATEWAY' ? 
                                               <Form.Item 
                                                    className   =   "gx-mb-0 gx-mt-1"
                                                    label       =   {t('Student Institution')}
                                                    rules       =   {[{ required: false, message: t('Please enter your student institution!') }]} 
                                                    name        =   "Institution"
                                                >
                                                    <Input 
                                                        type            =   "text" 
                                                        placeholder     =   "Institution name" 
                                                        name            =   "Institution" 
                                                    />
                                                </Form.Item>
                                                :
                                                <></>
                                            }

                                           

                                        </Col>

                                        <Col span={12}>

                                            <Form.Item
                                                className   =   "gx-mb-0 gx-mt-0"
                                                label       =   {t(`${businessModeMapped_Student} ID`)}
                                                rules={[
                                                    {
                                                      validator: validateCustomIDInput,
                                                    },
                                                  ]}
                                                // rules       =   {[{ required: true, message: t('Please enter a unique student ID!') }]}
                                                name        =   "StudentID"
                                                // tooltip     =   "Students ID is fixed"
                                            >
                                                <Input
                                                    disabled        =  {isModifyCustomID === true ? false : true}
                                                    type        =   "text"
                                                    placeholder =   "007"
                                                    name        =   "StudentID"
                                                    prefix      =   "ID-"

                                                />
                                            </Form.Item>


                                            <Form.Item
                                                className       =   "gx-mb-0 gx-mt-3"
                                                name            =   "StudentPicture"
                                                label           =   {t(`${businessModeMapped_Student} Picture`)}
                                                valuePropName   =   "fileList"
                                                getValueFromEvent=  {normFile}
                                            >
                                                <ImgCrop rotate fillColor={'rgba(0,0,0,0)'}>

                                                    <Upload
                                                        name="StudentPicture"
                                                        maxCount={1}
                                                        action={(file) => console.log(file)}
                                                        showUploadList={false}
                                                        customRequest={(x) => {
                                                            setStudentPic(x.file);
                                                            setUploadPicText(x.file.name);
                                                        }}
                                                        
                                                        listType="picture"
                                                        beforeUpload={(file) => {
                                                            const isPNG = (file.type === 'image/png') || (file.type === 'image/jpg') || (file.type === 'image/jpeg');

                                                            if (!isPNG) {
                                                                message.error(`${file.name} is not a png, jpg or jpeg file`);
                                                            }

                                                            return isPNG || Upload.LIST_IGNORE;
                                                        }}
                                                    >
                                                        <Button block icon={<UploadOutlined />} className="gx-btn-dullLavender" type="dashed">
                                                            {uploadPicText}
                                                        </Button>
                                                    </Upload>

                                                </ImgCrop>
                                            </Form.Item>

                                            <Form.Item
                                                className   =   "gx-mb-0 gx-mt-1"
                                                label       =   {`${businessModeMapped_Student} RFID Code`}
                                                rules       =   {[{ required: false}]}
                                                name        =   "StudentRFIDCode"
                                            >
                                                <Input
                                                    type        =   "text"
                                                    placeholder =   "4569112331"
                                                    name        =   "StudentRFIDCode"
                                                />
                                            </Form.Item>

                                         


                                        </Col>

                                    </Row>
                                </Panel>


                                {get(db, ['ReactAppMode'], undefined) === 'SMS_LMS_GATEWAY' ? <>
                                    <Panel 
                                        header={<PanelHead title={t("Parents / Guardians")} isLineAfterText={false} titleLevel={5} />} 
                                        key="2"
                                        style={panelStyle} 
                                    >
                                        <Row justify='space-evenly'>
                                            <Col span={12} >

                                                <Form.Item
                                                    className   =   "gx-mb-0 gx-mt-0"
                                                    label       =   {t('Parent 1 Phone')}
                                                    rules       =   {[{ required: false }]}
                                                    name        =   "Parent1Phone"

                                                >
                                                    <Input
                                                        type        =   "text"
                                                        placeholder =   "1700766172"
                                                        name        =   "Parent1Phone"
                                                        prefix      =   "+880"
                                                    />
                                                </Form.Item>

                                            </Col>

                                            <Col span={12}>

                                                <Form.Item
                                                    className   =   "gx-mb-0 gx-mt-0"
                                                    label       =   {t('Parent 2 Phone')}
                                                    rules       =   {[{ required: false }]}
                                                    name        =   "Parent2Phone"
                                                >
                                                    <Input
                                                        type        =   "text"
                                                        placeholder =   "1700766172"
                                                        name        =   "Parent2Phone"
                                                        prefix      =   "+880"
                                                    />
                                                </Form.Item>

                                            </Col>

                                        </Row>
                                    </Panel>
                                </> : <></>}

                                {
                                    extraInfoField.length > 0 ?
                                    <Panel header={<PanelHead title={t("Additional Information")} isLineAfterText={false} titleLevel={5}/>} key="3" style={{fontSize: '18px', margin: '0px 20px', marginTop: '30px'}} >
                                        <Row justify='space-evenly'>
                                            {
                                                extraInfoField.map((formItem, i) => 
                                                
                                                        formItem['name'] === encodeString('Blood Group') ?
                                                    
                                                            <Col span={12} >
            
                                                                <Form.Item 
                                                                    className   =   "gx-mb-1 gx-mt-2"
                                                                    label       =   {t(formItem['label'])}
                                                                    rules       =   {[{ required: false }]} 
                                                                    name        =   {formItem['name']}
            
                                                                >
                                                                    <Select 
                                                                        style           =   {{ width: '100%' }} 
                                                                        name            =   {formItem['name']}
                                                                        onChange        =   {(v) => setStudentBloodGroup(v)}
                        
                                                                    >

                                                                        {bloodGroups.map((option) => (
                                                                            <Select.Option value={option} key={option}>
                                                                                {option}
                                                                            </Select.Option>
                                                                        ))}

                                                                    </Select>
                                                                </Form.Item>
            
                                                            </Col> 
                                                        
                                                        :

                                                        formItem['name'] === encodeString('Date Of Birth') ?
                                                            <Col span={12} >
            
                                                                <Form.Item 
                                                                    className   =   "gx-mb-1 gx-mt-2"
                                                                    label       =   {t(formItem['label'])}
                                                                    rules       =   {[{ required: false }]} 
                                                                    name        =   {formItem['name']}
            
                                                                >
                                                                    <DatePicker 
                                                                        className='gx-w-100'
                                                                        format="DD-MM-YYYY"
                                                                        value={studentDateOfBirth}
                                                                        onChange={(date, dateString)=> setStudentDateOfBirth(dateString)} 
                                                                    />
                                                                </Form.Item>
            
                                                            </Col> 
                                                        :

                                                            <Col span={12} >
            
                                                                <Form.Item 
                                                                    className   =   "gx-mb-1 gx-mt-2"
                                                                    label       =   {t(formItem['label'])}
                                                                    rules       =   {[{ required: false }]} 
                                                                    name        =   {formItem['name']}
            
                                                                >
                                                                    <Input 
                                                                        type            =   "text" 
                                                                        placeholder     =   {formItem['placeholder']} 
                                                                        name            =   {formItem['name']}
                                                                    
                                                                    />
                                                                </Form.Item>
            
                                                            </Col> 
                                                            
                                                        
                                                    )

                                            }
        
                                        </Row>
                                    </Panel>
                                    :
                                    <></>
                                }

                                {hideAddedBatchInfo !== true ? <>
                                
                                    <Panel 
                                        header  =   {<PanelHead title={t(`Included ${businessModeMapped_Classrooms}`)} isLineAfterText={false} titleLevel={5} />} 
                                        extra   =   {<Button type="primary" size="small" className="gx-text-white gx-mb-3" onClick={()=> showModal()}>{t(`Add To Another ${businessModeMapped_Classrooms}`)} +</Button>} 
                                        key     =   "4" 
                                        collapsible = {'disabled'}
                                        style   =   {panelStyle} 
                                    >

                                        <Row>
                                            {accBatchesJx}
                                        </Row>
        
                                    </Panel>
                                
                                
                                </> : <></>}

                            </Collapse>
                        </div>
                    </div>

                </Form>

            </Drawer>


            <Modal 
                title       =   {`${businessModeMapped_Classrooms} To Include In`}
                visible     =   {isModalVisible} 
                footer      =   {[<></>]}
                onCancel    =   {handleCancel}
                centered
                width       =   {1000}
                className   =   "batch-include-modal gx-p-0"
                zIndex      =   {1005}
            >
                <Form
                    form                    =   {form}
                    onFinish                =   {handleOk}
                    onFinishFailed          =   {onFinishFailed}
                    layout                  =   'vertical'
                    className               =   "gx-force-two-cols"
                >
                    <Input.Search
                        placeholder         = {t(`Search ${businessModeMapped_Classrooms}...`)}
                        onChange            = {handleSearch}
                        className           = {"gx-my-3 gx-px-3"}
                    />
                    
                    <div className='modal-form-items'>
                        <Form.Item 
                            className   =   "gx-mb-0"
                            label       =   ''
                            rules       =   {[{ required: false, message: t(`At least one ${businessModeMapped_classrooms} must be picked!`) }]} 
                            name        =   "includeBatchCBOX"
                            style       =   {{minHeight:'50vh'}}
                        >
                            <Checkbox.Group 
                                style           =   {{ width: '100%'}} 
                                name            =   "includeBatchCBOX"
                            >
                                <Row>
                                    {modalJx.length > 0 ? modalJx : <h4>{t(`There are no ${businessModeMapped_classrooms} to include`)}...</h4>}
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>
                    </div>
 
                    <Form.Item shouldUpdate className="custom-modal-footer">
                    {({ getFieldValue }) => {
                        const isDisabled = !getFieldValue("includeBatchCBOX")?.length;
                    
                        return (
                            <>
                                <Button className="gx-mb-1" onClick={handleCancel}>
                                    {t("Cancel")}
                                </Button>

                                <Tooltip title={isDisabled ? t("Please select at least 1 classroom") : ""}>
                                    <span> 
                                        <Button className="gx-mb-1" type="primary"  htmlType="submit" disabled={isDisabled} >
                                            {t("Add")}
                                        </Button>
                                    </span>
                                </Tooltip>
                            </>
                        );
                    }}
                    </Form.Item>
                </Form>
                
            </Modal>


            <Modal
                title           =   {t("You have unsaved changes. Do you want to save them?")}
                visible         =   {unsavedModal}
                onCancel        =   {() => {
                    setUnsavedModal(false);
                    setUnsavedChanges(false);
                    onClose();
                }}
                bodyStyle       =   {{ padding: 0, margin: 0 }}
                wrapClassName   =   {'PromptWithMultiOptions'}
                style           =   {{top: 200}}
                zIndex          =   {1006}

                footer={[
                    <div key={12312347653847}>
                        <Button loading={loading} onClick={() => {
                                setUnsavedModal(false);
                                setUnsavedChanges(false);
                                onClose();
                            }} size="medium" className='gx-text-white gx-mb-0 gx-btn-actionBlue'>{t("No")}</Button>
                        
                        <Button loading={loading} onClick={() => {
                            //submit form
                            submitButton_REF.current.click();
                        }} size="medium" className='gx-text-white gx-mb-0 gx-btn-darkerActionBlue'>{t("Yes, Save and Implement Changes")}</Button>
                    </div>

                ]}
            >
                <span style={{ height: '5px' }}>&nbsp;</span>

            </Modal>
        </>
    );
};

export default TeacherAssistantEditStudentDrawer;
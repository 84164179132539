import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Define image data
const firstRowData = [
    // { src: '/assets/images/welcomev2/community/itz_principal_using_edutechs.jpg', alt: 'Teacher 1', type: 'image-card' },
    {
        src: "/assets/images/welcomev2/client/laila.png",
        alt: "Teacher 1",
        profileImage: "https://iili.io/33hMIa9.png",
        name: "Mannan Shafiq",
        designation: "English Teacher, Edbridge",
        testimonial: "Great support and help. We are proud to be making the transition to enabling digital tools in our classrooms to make the lives of our teachers and students better!",
        type: 'testimonial-card'
    },
    { src: 'https://iili.io/33hwWut.jpg', alt: 'Teacher 5', type: 'image-card' },
    {
        src: "/assets/images/welcomev2/image03.jpg",
        alt: "Teacher 3",
        profileImage: "/assets/images/welcomev2/client/refayet.png",
        name: "Refayat Sir",
        designation: "Teacher, Legends School",
        testimonial: "Thank you for leading the continuous improvement of Edutechs which made our businesses smoother than before. May Edutechs become bigger and better over time. Profound regards.",
        type: 'testimonial-card'
    },
    // { src: '/assets/images/welcomev2/community/mac_master_students_playing.jpg', alt: 'Teacher 11', type: 'image-card' },
    // {
    //     src: "/assets/images/welcomev2/image07.jpg",
    //     alt: "Teacher 3",
    //     profileImage: "/assets/images/welcomev2/client/mamun.png",
    //     name: "Mohammad Al Mamun",
    //     designation: "Founder, ITT Academic Care",
    //     testimonial: "Thanks to Edutechs, managing my educational institute has become incredibly easy. With their user-friendly software, I can now accomplish tasks that previously required 3 to 4 people to complete, all by myself. I'm grateful for...",
    //     type: 'testimonial-card'
    // },

];

const secondRowData = [
    // {
    //     src: "/assets/images/welcomev2/image07.jpg",
    //     alt: "Teacher 3",
    //     profileImage: "/assets/images/welcomev2/client/adeeb.png",
    //     name: "Adeeb Ahsan",
    //     designation: "Mathematics Teacher",
    //     testimonial: "Very easy to use and irreplaceable for me especially during the Covid era when I was forced to transition my classrooms to a digital version.",
    //     type: 'testimonial-card'
    // },
    
    { src: '/assets/images/welcomev2/community/refayat_sir_taking_class.jpg', alt: 'Teacher 5', type: 'image-card' },
    {
        src: "/assets/images/welcomev2/client/image01.jpg",
        alt: "Teacher 1",
        profileImage: "https://iili.io/33haTzB.png",
        name: "Shabbir Sir",
        designation: "Physics Teacher, Edbase",
        testimonial: "Created by my ex-students. They have made my classroom management much easier and have created all advanced custom features that I have asked for.",
        type: 'testimonial-card'
    },

    { src: 'https://iili.io/33hhhJI.jpg', alt: 'Teacher 10', type: 'image-card' },
    // {
    //     src: "/assets/images/welcomev2/image03.jpg",
    //     alt: "Teacher 3",
    //     profileImage: "/assets/images/welcomev2/client/riaz.png",
    //     name: "Riaz Sir",
    //     designation: "Accounting Instructor",
    //     testimonial: "Edutechs' new payment management feature has completely revolutionized my institute's payment handling. Previously, tracking payments, verifying records, and sending reminders were all challenges. Now, the process is effortless.",
    //     type: 'testimonial-card'
    // },
    
];

const CommunitySection = () => {
    const {t}                                                = useTranslation(); 


    // State to keep track of window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    console.log('windowWidth', windowWidth)

    // Effect to update window width on resize
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // const getTransformStyle = (index) => {

    //     if (index === 0) {
    //         return { transform: `translateX(-50px)` }
    //     }
    //     if (index === 1) {

    //         return { transform: `translateX(-30px)` }
    //     }
    //     if (index === 2) {
    //         return { transform: `translateX(-10px)` }
    //     }
    //     if (index === 3) {
    //         return { transform: `translateX(10px)` }
    //     }
    //     if (index === 4) {
    //         return { transform: `translateX(30px)` }
    //     }
    //     if (index === 5) {
    //         return { transform: `translateX(50px)` }
    //     }

    //     if (index === 6) {
    //         return { transform: `translateX(-160px)` }
    //     }
    //     if (index === 7) {

    //         return { transform: `translateX(-130px)` }
    //     }
    //     if (index === 8) {
    //         return { transform: `translateX(-100px)` }
    //     }
    //     if (index === 9) {
    //         return { transform: `translateX(10px)` }
    //     }
    //     if (index === 10) {
    //         return { transform: `translateX(30px)` }
    //     }
    //     if (index === 11) {
    //         return { transform: `translateX(50px)` }
    //     }

    //     return {};
    // };


    return (
        <section className="gx-our-community-section gx-section-padding">
            <div className="gx-landing-container">
                <div className="gx-section-title">
                    {/* <img src="/assets/images/welcomev2/vector6.png" alt="Love" /> */}
                    <h1>{t("Institutions on the path to Success")}</h1>
                </div>
                <div className="gx-images-flex-container">
                {/* First Row */}
                <div className="gx-image-row gx-community-image-row">
                    {firstRowData.map((item, index) => (
                        item.type === 'image-card' ? (
                            <div key={index} className={`gx-image-wrapper gx-community-${index + 1}`}>
                                <img
                                    src={item.src}
                                    alt={item.alt}
                                    className="gx-rounded-images"
                                    // style={{ width: imageSize, height: imageSize }}
                                />
                            </div>
                        ) : (
                            <div key={index} className={`gx-card-wrapper gx-text-box-card gx-community-${index + 1}`}>
                                <div className="gx-profile-wrapper">
                                    <img src={item.profileImage} alt={item.name} className="gx-profile-image" />
                                    <div className="gx-profile-info">
                                        <h5>{t(item.name)}</h5>
                                        <p>{t(item.designation)}</p>
                                    </div>
                                </div>
                                <div className="gx-testimonial">
                                    <p>{t(item.testimonial.length > 20 ? item.testimonial.slice(0, 20) : item.testimonial)}</p>
                                </div>
                            </div>
                        )
                    ))}
                </div>

                {/* Second Row */}
                <div className="gx-image-row gx-mt-5 gx-community-image-row">
                    {secondRowData.map((item, index) => (
                        item.type === 'image-card' ? (
                            <div key={index} className={`gx-image-wrapper gx-community-${index + 6}`}>
                                <img
                                    src={item.src}
                                    alt={item.alt}
                                    className="gx-rounded-images"
                                    // style={{ width: imageSize, height: imageSize }}
                                />
                            </div>
                        ) : (
                            <div key={index} className={`gx-card-wrapper gx-text-box-card gx-community-${index + 6}`}>
                                <div className="gx-profile-wrapper">
                                    <img src={item.profileImage} alt={item.name} className="gx-profile-image" />
                                    <div className="gx-profile-info">
                                        <h5>{t(item.name)}</h5>
                                        <p>{t(item.designation)}</p>
                                    </div>
                                </div>
                                <div className="gx-testimonial">
                                    <p>{t(item.testimonial.length > 20 ? item.testimonial.slice(0, 20) : item.testimonial)}</p>
                                </div>
                            </div>
                        )
                    ))}
                </div>
            </div>
            </div>
        </section>
    );
};

export default CommunitySection;
